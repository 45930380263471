import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import {
  getProjectNameFromID,
  resizeTextAnswer,
  formatDate,
  logQA,
} from "./helpers";
import {
  UnitsRadio,
  DPModuleInput,
  DecimalPlacesSelect,
  ButtonBlockTextArea,
  SaveButtonBlock,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorDimensions({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)
  const [saveMessage, setSaveMessage] = React.useState("");

  // Common calculator helper functions
  var radioValue;
  var unitWord;
  var ODIDWord = "OD";

  function isPrime(number) {
    var maxTest = parseInt(Math.sqrt(number) + 1);
    var primeNumber = true;
    for (let i = 2; i < maxTest; i++) {
      if (number % i === 0) {
        primeNumber = false;
        break;
      }
    }
    return primeNumber;
  }

  function clearInputs(event) {
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-pangle").value = "";
    document.getElementById("input-module").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  async function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'single' for one gear
    // Loading the dropdown should match this record type

    // Get project name
    var saveInputResponse = await saveInputs(
      event,
      "single",
      project.project,
      false
    );
    setSaveMessage(saveInputResponse);
  }

  // Change the placeholder depending on the user's selection
  var z;
  var m_n;
  var alpha_n_Degree;
  var alpha_n; //= parseFloat(alpha_n_Degree)*Math.PI/180;
  var precision;

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();

    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var module = document.getElementById("input-module").value;
    var pressureAngle = document.getElementById("input-pangle").value;
    precision = document.getElementById("precision").value;

    logQA({
      page: "GCSdimensions",
      numteeth: numTeeth,
      module: module,
      pangle: pressureAngle,
      param7: unitWord,
    });

    var errorMessage = "Error";
    var okToCalc = true;

    if (
      document.getElementsByName("input-type")[0].checked ===
      document.getElementsByName("input-type")[1].checked
    ) {
      //Both Internal and External are the same (checked or unchecked)
      okToCalc = false;
      errorMessage = "Gear must be either Internal or External";
    }

    if (!(numTeeth >= 6 && numTeeth <= 1000)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of teeth must be between 6 and 1000";
    }
    if (!Number.isInteger(numTeeth)) {
      okToCalc = false;
      errorMessage = "Number of teeth must be an integer";
    }
    if (!(module >= 0.1 && module <= 300)) {
      okToCalc = false;
      errorMessage = errorMessage + "\nModule/DP must be between 0.1 and 300";
    }
    if (!(pressureAngle >= 1 && pressureAngle <= 45)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nPressure Angle must be between 1 and 45 degrees";
    }
    if (!(precision >= 0 && precision <= 8)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of Decimal Places must be between 0 and 8";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer();
    }
  }
  function calculateMOW() {
    z = parseFloat(document.getElementById("input-numteeth").value);
    m_n = parseFloat(document.getElementById("input-module").value);
    alpha_n_Degree = parseFloat(document.getElementById("input-pangle").value);
    alpha_n = (parseFloat(alpha_n_Degree) * Math.PI) / 180;
    precision = document.getElementById("precision").value;

    // Rough fix to compile in React
    let gearType;
    let modWord;
    let pitchDiameter;
    let baseCircle;
    let diametralPitch;
    let dedendum;
    let workingDepth;
    let addendum;
    let wholeDepth;
    let tipDiameter;
    let rootDiameter;
    let textToWrite;

    let primeTeeth = isPrime(z);

    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }

    if (document.getElementsByName("input-type")[1].checked) {
      //check if the gear is internal
      gearType = "Internal";
      ODIDWord = "ID";
    } else {
      gearType = "External";
      ODIDWord = "OD";
    }

    var m_nInput = m_n;
    var unitConversion;
    if (radioValue === "in") {
      m_n = 25.4 / m_n;
      unitWord = "inches";
      modWord = "Diametral Pitch";
      unitConversion = 1;
    } else {
      unitWord = "millimeters";
      modWord = "Module";
      unitConversion = 25.4;
    }

    ///BASIC GEAR DIMENSIONS

    pitchDiameter = (m_n * z * unitConversion) / 25.4;
    baseCircle = pitchDiameter * Math.cos(alpha_n);
    diametralPitch = 25.4 / m_n;

    addendum = (1 / diametralPitch) * unitConversion;
    dedendum = (1.25 / diametralPitch) * unitConversion;
    workingDepth = (2 / diametralPitch) * unitConversion;
    wholeDepth = (2.25 / diametralPitch) * unitConversion; //(preferred)

    if (gearType === "External") {
      tipDiameter = pitchDiameter + 2 * addendum; // tip diameter
      rootDiameter = tipDiameter - 2 * wholeDepth; // (preferred)
    } else {
      tipDiameter = pitchDiameter - 2 * addendum;
      rootDiameter = pitchDiameter + 2 * addendum;
    }

    var circularPitch = (Math.PI / diametralPitch) * unitConversion;
    var toothThickness = circularPitch / 2; // nominally half the pitch is tooth, half is tooth space

    var undercutThreshold = 2 / Math.sin(alpha_n) / Math.sin(alpha_n);
    var undercutRisk = "No";
    if (z <= undercutThreshold) {
      undercutRisk = "Yes";
    }

    if (radioValue === "in") {
      // reset units to millimeters (to display both)
      pitchDiameter = pitchDiameter * 25.4;
      tipDiameter = tipDiameter * 25.4;
      rootDiameter = rootDiameter * 25.4;
      baseCircle = baseCircle * 25.4;
      addendum = addendum * 25.4;
      dedendum = dedendum * 25.4;
      workingDepth = workingDepth * 25.4;
      wholeDepth = wholeDepth * 25.4;
      circularPitch = circularPitch * 25.4;
      toothThickness = toothThickness * 25.4;
    }

    //textToWrite = "Inputs\nNumber of Teeth:\t" + z + "\n" + modWord + ":\t" + m_nInput + "\nPressure Angle:\t" + alpha_n_Degree + "\tdegrees\nGear Type:\t" + gearType + "\n\nOutputs\n" + ODIDWord +" Reference:  \t" + tipDiameter.toFixed(precision) + "\tmm\t" + (tipDiameter/25.4).toFixed(precision) +"\tin\nPitch Diameter:\t" + pitchDiameter.toFixed(precision) + "\tmm\t" + (pitchDiameter/25.4).toFixed(precision) + "\tin\nRoot Diameter: \t" + rootDiameter.toFixed(precision) + "\tmm\t" + (rootDiameter/25.4).toFixed(precision) +"\tin\n\nAddendum:       \t" + addendum.toFixed(precision) + "\tmm\t" + (addendum/25.4).toFixed(precision) +"\tin\nDedendum:       \t" + dedendum.toFixed(precision) + "\tmm\t" + (dedendum/25.4).toFixed(precision) +"\tin\nWorking Depth:  \t" + workingDepth.toFixed(precision) + "\tmm\t" + (workingDepth/25.4).toFixed(precision) +"\tin\nWhole Depth:    \t" + wholeDepth.toFixed(precision) + "\tmm\t" + (wholeDepth/25.4).toFixed(precision) +"\tin\nCircular Pitch: \t" + circularPitch.toFixed(precision) + "\tmm\t" + (circularPitch/25.4).toFixed(precision) +"\tin\nTooth Thickness:\t" + toothThickness.toFixed(precision) + "\tmm\t" + (toothThickness/25.4).toFixed(precision) +"\tin\nBase Circle:   \t" + baseCircle.toFixed(precision) + "\tmm\t" + (baseCircle/25.4).toFixed(precision) + "\tin\nUndercut:       \t" + undercutRisk +  "\n\n\u00A9 " + new Date().getFullYear() + " Evolvent Design,\t" + formatDate(new Date()) + "\nGCS0";

    // Requested to add the project name and gear name to the output
    // Only project ID is passed via state.

    var displayProjectName = getProjectNameFromID(project.project);
    var displayGearName = document.getElementById("input-gearname")
      ? document.getElementById("input-gearname").value
      : "";

    textToWrite = `Inputs
Number of Teeth:\t${z}
${modWord}:\t${m_nInput}
Pressure Angle:\t${alpha_n_Degree}\tdegrees
Gear Type:\t${gearType}

Outputs
${ODIDWord} Reference:  \t${tipDiameter.toFixed(precision)}\tmm\t${(
      tipDiameter / 25.4
    ).toFixed(precision)}\tin
Pitch Diameter:\t${pitchDiameter.toFixed(precision)}\tmm\t${(
      pitchDiameter / 25.4
    ).toFixed(precision)}\tin
Root Diameter: \t${rootDiameter.toFixed(precision)}\tmm\t${(
      rootDiameter / 25.4
    ).toFixed(precision)}\tin

Addendum:       \t${addendum.toFixed(precision)}\tmm\t${(
      addendum / 25.4
    ).toFixed(precision)}\tin
Dedendum:       \t${dedendum.toFixed(precision)}\tmm\t${(
      dedendum / 25.4
    ).toFixed(precision)}\tin
Working Depth:  \t${workingDepth.toFixed(precision)}\tmm\t${(
      workingDepth / 25.4
    ).toFixed(precision)}\tin
Whole Depth:    \t${wholeDepth.toFixed(precision)}\tmm\t${(
      wholeDepth / 25.4
    ).toFixed(precision)}\tin
Circular Pitch: \t${circularPitch.toFixed(precision)}\tmm\t${(
      circularPitch / 25.4
    ).toFixed(precision)}\tin
Tooth Thickness:\t${toothThickness.toFixed(precision)}\tmm\t${(
      toothThickness / 25.4
    ).toFixed(precision)}\tin
Base Circle:   \t${baseCircle.toFixed(precision)}\tmm\t${(
      baseCircle / 25.4
    ).toFixed(precision)}\tin
Undercut:       \t${undercutRisk}
Prime:          \t${primeTeeth ? "Yes" : "No"}
\u00A9 ${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}

Project:\t${displayProjectName}
Gear:\t${displayGearName}
GCS0`;

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <div className="project-name">Dimensions</div>
        <div>
          Calculate the geometry of key dimensions for your external or internal
          spur gear. The input only requires 3 things:{" "}
          <ol>
            <li>Tooth count (integer)</li>
            <li>Pitch (1/inch) or Module (mm)</li>
            <li>Pressure Angle (degrees)</li>
          </ol>
          <p>
            Flags notify when your gear has a prime number of teeth, or risk of
            undercut in the gear tooth profile.
          </p>
          <p style={{ marginBottom: 0 }}>
            Whole Depth is usually the depth of cut, but it depends on the
            system being used; we use the standard 2.25/DP for our calculations.
          </p>
        </div>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="single"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={calculate}
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio />

              <div style={{ marginBottom: 10 + "px" }}>
                <label className="unitslabel">Type</label>
                <div>
                  <input
                    className="radioButton"
                    type="radio"
                    id="input-type-ext"
                    value="ext"
                    name="input-type"
                    defaultChecked
                  />
                  <label className="radioLabel" htmlFor="input-type-ext">
                    External
                  </label>
                </div>
                <div>
                  <input
                    className="radioButton"
                    type="radio"
                    id="input-type-int"
                    value="int"
                    name="input-type"
                  />
                  <label className="radioLabel" htmlFor="input-type-int">
                    Internal
                  </label>
                </div>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Teeth<span className="required">*</span>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-numteeth"
                  size="25"
                />
              </div>

              <DPModuleInput />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pange">
                  Pressure Angle (degrees)<span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Common values: <br />
                      20, 14.5, 25
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-pangle"
                  size="25"
                />
              </div>

              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />

              {userLevel > 0 && (
                <SaveButtonBlock
                  project={project}
                  setProject={setProject}
                  saveInputsHandler={saveInputsHandler}
                  saveMessage={saveMessage}
                />
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/dimensions-1a.png" fluid />
            <br />
            <p>There are multiple depth systems in use:</p>
            <ul>
              <li>2.25/DP - Common modern</li>
              <li>2.157/DP - Older, often 14.5º PA</li>
              <li>1.8/DP - Used in splines</li>
              <li>2.2DP +0.002" - European</li>
            </ul>
            <p>
              Depth systems are different ways to express the clearance needed
              between the tip of one gear tooth, and the root of its mate.
            </p>
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          <span className="term-emphasis">Base Circle</span> is not a physical
          diameter on the gear, but it is the theoretical diameter from which
          the true involute emanates.
        </p>
        <p>
          <span className="term-emphasis">Undercut</span> flag indicates whether
          an undercut (when the involute profile cuts into the root of the gear
          tooth) is present. This typically occurs on low tooth count gears, but
          is also a function of pressure angle. An undercut can greatly reduce
          the strength of a gear, and should generally be avoided. Additional
          design modifications can also help minimize undercuts.
        </p>
        <p>
          <span className="term-emphasis">Prime</span> flag indicates whether
          the tooth count is a prime number.
        </p>
        <p>
          <span className="term-emphasis">Whole Depth</span> (Addendum+Dedendum)
          can be different than the depth of cut, depending on the design
          system. The graphic shows the common depth systems and how to
          calculate the number if your gear cutter does not match the
          calculator.
        </p>
      </Card>
    </>
  );
}
