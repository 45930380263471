import React from "react";

import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import { DecimalPlacesSelect, ButtonBlockTextArea } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorDP({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  function clearInputs(event) {
    document.getElementById("input-module").value = "";
    document.getElementById("input-cp").value = "";
    document.getElementById("input-dp").value = "";
    document.getElementById("input-tpi").value = "";
    document.getElementById("input-module").disabled = false;
    document.getElementById("input-cp").disabled = false;
    document.getElementById("input-dp").disabled = false;
    document.getElementById("input-tpi").disabled = false;
    setTextAreaText("");
    resizeTextAnswer();
    event.preventDefault();
    return false;
  }

  // Disable other boxes once you start typing in one
  function changeModule() {
    if (document.getElementById("input-module").value !== "") {
      document.getElementById("input-cp").disabled = true;
      document.getElementById("input-dp").disabled = true;
      document.getElementById("input-tpi").disabled = true;
    } else {
      document.getElementById("input-cp").disabled = false;
      document.getElementById("input-dp").disabled = false;
      document.getElementById("input-tpi").disabled = false;
    }
    return false;
  }
  function changeCP() {
    if (document.getElementById("input-cp").value !== "") {
      document.getElementById("input-module").disabled = true;
      document.getElementById("input-dp").disabled = true;
      document.getElementById("input-tpi").disabled = true;
    } else {
      document.getElementById("input-module").disabled = false;
      document.getElementById("input-dp").disabled = false;
      document.getElementById("input-tpi").disabled = false;
    }
    return false;
  }
  function changeDP() {
    if (document.getElementById("input-dp").value !== "") {
      document.getElementById("input-cp").disabled = true;
      document.getElementById("input-module").disabled = true;
      document.getElementById("input-tpi").disabled = true;
    } else {
      document.getElementById("input-cp").disabled = false;
      document.getElementById("input-module").disabled = false;
      document.getElementById("input-tpi").disabled = false;
    }
    return false;
  }
  function changeTPI() {
    if (document.getElementById("input-tpi").value !== "") {
      document.getElementById("input-cp").disabled = true;
      document.getElementById("input-module").disabled = true;
      document.getElementById("input-dp").disabled = true;
    } else {
      document.getElementById("input-cp").disabled = false;
      document.getElementById("input-module").disabled = false;
      document.getElementById("input-dp").disabled = false;
    }
    return false;
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var validTest = true;

    var precision = parseInt(document.getElementById("precision").value);
    var iModule = parseFloat(document.getElementById("input-module").value);
    var iCP = parseFloat(document.getElementById("input-cp").value);
    var iDP = parseFloat(document.getElementById("input-dp").value);
    var iTPI = parseFloat(document.getElementById("input-tpi").value);

    logQA({
      page: "GCSgearpitch",
      module: iModule,
      param5: iCP,
      param6: iDP,
      param7: iTPI,
    });

    var errorMessage, oModule, oDP, oCP, oTPI;
    if (isNaN(iModule) && isNaN(iCP) && isNaN(iDP) && isNaN(iTPI)) {
      validTest = false;
      errorMessage = "One valid input is required";
    }

    if (isNaN(precision)) {
      validTest = false;
      errorMessage = "Number of decimal places must be an integer";
    }

    if (iModule < 0 || iCP < 0 || iDP < 0 || iTPI < 0) {
      validTest = false;
      errorMessage = "Input must be a positive value";
    }

    if (validTest) {
      if (!isNaN(iModule)) {
        oModule = iModule;
        oDP = 25.4 / iModule;
        oCP = Math.PI / oDP;
        oTPI = 1 / oCP;
        writeAnswer(
          "Module",
          "mm",
          oModule,
          oModule,
          oDP,
          oCP,
          oTPI,
          precision
        );
      }
      if (!isNaN(iCP)) {
        oCP = iCP;
        oDP = Math.PI / oCP;
        oModule = 25.4 / oDP;
        oTPI = 1 / oCP;
        writeAnswer(
          "Circular Pitch",
          "in",
          oCP,
          oModule,
          oDP,
          oCP,
          oTPI,
          precision
        );
      }
      if (!isNaN(iDP)) {
        oDP = iDP;
        oCP = Math.PI / oDP;
        oModule = 25.4 / oDP;
        oTPI = 1 / oCP;
        writeAnswer(
          "Diametral Pitch",
          "1/in",
          oDP,
          oModule,
          oDP,
          oCP,
          oTPI,
          precision
        );
      }
      if (!isNaN(iTPI)) {
        oTPI = iTPI;
        oCP = 1 / iTPI;
        oDP = Math.PI * oTPI;
        oModule = 25.4 / oDP;

        writeAnswer(
          "Threads per Inch",
          "1/in",
          oTPI,
          oModule,
          oDP,
          oCP,
          oTPI,
          precision
        );
      }
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }
  }
  function writeAnswer(
    inputText,
    inputUnits,
    inputValue,
    oModule,
    oDP,
    oCP,
    oTPI,
    precision
  ) {
    var textToWrite;
    textToWrite =
      "Inputs\n" +
      inputText +
      ":\t" +
      inputValue.toFixed(precision) +
      "\t" +
      inputUnits +
      "\n\nOutputs\nModule:\t" +
      oModule.toFixed(precision) +
      "\tmm\nDiametral Pitch:\t" +
      oDP.toFixed(precision) +
      "\t1/in\nCircular Pitch: \t" +
      oCP.toFixed(precision) +
      "\tin\nThreads per Inch: \t" +
      oTPI.toFixed(precision) +
      "\t1/in";
    textToWrite =
      textToWrite +
      "\n\n\u00A9 " +
      new Date().getFullYear() +
      " Evolvent Design,\t" +
      formatDate(new Date()) +
      "\nGCS0";

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <div className="project-name">Pitch Conversion</div>
        <p>
          There are multiple systems that can be used to describe gear pitch
          geometry:
        </p>
        <p>
          <span className="term-emphasis">Module</span> uses Metric units (mm)
          and is the ratio of the reference diameter (pitch diameter) to the
          number of teeth. Higher numbers make larger gears.
        </p>
        <p>
          <span className="term-emphasis">Diametral Pitch</span> uses English
          units (1/in) and is the number of teeth per inch of the pitch circle.
          Higher numbers make smaller gears.
        </p>
        <p>
          <span className="term-emphasis">Circular Pitch</span> uses English
          units (1/in) and is a measure of the arc distance along the reference
          diameter between adjacent teeth. Higher numbers make smaller gears;
          circular pitch is commonly used for threads.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-module">Module (mm)</label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-module"
                  size="35"
                  onChange={changeModule}
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-dp">Diametral Pitch (1/inch)</label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-dp"
                  size="35"
                  onChange={changeDP}
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-cp">Circular Pitch (inch)</label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-cp"
                  size="35"
                  onChange={changeCP}
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-tpi">Threads Per Inch</label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-tpi"
                  size="35"
                  onChange={changeTPI}
                />
              </div>

              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/dp-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          Tooth size is proportional to the gear's Module, so a higher module
          will make a large, coarse gear.
        </p>
        <p>
          {" "}
          By contrast, tooth size is inversely proportional to diametral pitch
          and circular pitch. A gear with a high diametral pitch will make
          small, fine teeth, like those found in watches.
        </p>
        <p>
          Diametral pitch is most commonly used in the US, Canada, and Great
          Britain. It was one of the earlier systems of defining gear tooth
          sizes, followed by Module that is in use globally at this time.
          Circular pitch is often used when referring to standard threads and is
          most commonly used on lathes for threading operations.
        </p>
      </Card>
    </>
  );
}
