import React from "react";

// Import Bootstrap style elements
import { Card, Col, Row } from "react-bootstrap";

export default function CalculatorDXF({ pageLevel, userLevel }) {
  return (
    <>
      <Card className="project-card">
        <div className="project-name">DXF Generator</div>
        <p>
          This calculator exports .DXF and .SVG files that are dimensionally
          correct, units are millimeters.
        </p>
        <p>
          <span className="term-emphasis">.DXF</span> is commonly used for
          drafting and designing, and may be helpful for waterjets, laser
          cutters, and other tools.
          <br />
          <span className="term-emphasis">.SVG</span> is a vector graphics
          format, and commonly used for drawings, designs, and illustrations.
        </p>
        <p>
          Based on tooth count (n), Gear 1 can be an{" "}
          <span className="term-emphasis">External Spur Gear (n&gt;0)</span>,{" "}
          <span className="term-emphasis">Internal Spur Gear (n&lt;0)</span>, or{" "}
          <span className="term-emphasis">Rack Gear (n=0)</span>.
        </p>
        <p>
          Generated gears may have "undercut" teeth for low tooth counts, which
          allows them to run without binding, but may weaken tooth strength.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <iframe
              title="OVC DXF"
              src="https://oilviscositychart.com/geardxf-gcs"
              width="900px"
              height="1500px"
              style={{
                border: "none",
                minWidth: "fit-content",
                pointerEvents: pageLevel > userLevel ? "none" : "all",
              }}
            ></iframe>
          </Col>
        </Row>
      </Card>
    </>
  );
}
