import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import {
  UnitsRadio,
  DPModuleInput,
  ButtonBlockTextArea,
  SaveButtonBlock,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorFandSPfauter({ userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)
  const [saveMessage, setSaveMessage] = React.useState("");
  const [saveMessageHob, setSaveMessageHob] = React.useState("");

  function clearInputs(event) {
    document.getElementById("input-module").value = "";
    document.getElementById("input-hobdia").value = "";
    document.getElementById("input-hardness").value = "";
    document.getElementById("input-machinability").value = "";

    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-pangle").value = "";
    document.getElementById("input-helical").value = "";
    document.getElementById("input-scallop").value = "";

    document.getElementById("input-matldrop").value = "default";
    document.getElementById("input-hobmatldrop").value = "default";

    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  async function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'single' for one gear
    // Loading the dropdown should match this record type

    // Get project name
    var saveInputResponse = await saveInputs(
      event,
      "single",
      project.project,
      false
    );
    setSaveMessage(saveInputResponse);
  }

  async function saveInputsHandlerHob(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'hob' for hobs
    // Loading the dropdown should match this record type

    // Get project name
    var saveInputResponse = await saveInputs(
      event,
      "hob",
      project.project,
      true
    );
    setSaveMessageHob(saveInputResponse);
  }

  ////////
  var modWord = "Module";

  function updateGearMaterial() {
    // get value from dropdown
    var gearMaterialValue = document.getElementById("input-matldrop").value;
    var gearHardness = document.getElementById("input-hardness").value;
    if (gearMaterialValue === "default") {
      document.getElementById("input-machinability").value = "Select Material";
      return;
    }

    var matlCoeffs = [
      // Min, Max, x^0, x^1, ... x^8
      [
        125.07875, 259.55199, 198004.51, -8792.0648, 168.88094, -1.8324327,
        0.012283918, -0.000052094652, 0.00000013649559, -0.00000000020205411,
        1.2941254e-13,
      ],
      [
        170.00742, 349.80443, -3208.8674, 74.185117, -0.71399716, 0.0037106932,
        -0.000010892014, 0.00000001696313, -0.000000000010883954, 0, 0,
      ],
      [
        170.00742, 349.80443, -3208.8674, 74.185117, -0.71399716, 0.0037106932,
        -0.000010892014, 0.00000001696313, -0.000000000010883954, 0, 0,
      ],
      [189.80243, 329.47698, 97.315427, -0.20635545, 0, 0, 0, 0, 0, 0, 0],
      [189.80243, 329.47698, 97.315427, -0.20635545, 0, 0, 0, 0, 0, 0, 0],
      [
        179.68749, 389.69638, 33376.647, -861.41341, 9.3635967, -0.055524003,
        0.00019424251, -0.00000040150709, 0.00000000045467961, -2.1787278e-13,
        0,
      ],
      [
        159.7407, 340.20613, 4642.778, -117.99502, 1.2328641, -0.0066919082,
        0.000019961056, -0.000000031143471, 0.000000000019919785, 0, 0,
      ],
      [
        189.41753, 319.94616, -529.46462, 8.7526064, -0.048715979,
        0.00012281392, -0.00000012134101, 0, 0, 0, 0,
      ],
      [
        179.97688, 389.80828, 3707.2146, -82.906892, 0.76045036, -0.0036136307,
        0.0000094116006, -0.000000012797978, 7.1282312e-12, 0, 0,
      ],
      [
        179.97688, 389.80828, 3707.2146, -82.906892, 0.76045036, -0.0036136307,
        0.0000094116006, -0.000000012797978, 7.1282312e-12, 0, 0,
      ],
      [
        230.34619, 339.485, 1595.4977, -19.823345, 0.095748639, -0.00020794918,
        0.00000017025664, 0, 0, 0, 0,
      ],
      [199.90871, 339.85849, 86.63607, -0.18437766, 0, 0, 0, 0, 0, 0, 0],
    ];

    var matlIndex = (gearMaterialValue = gearMaterialValue - 1); // graph 1 is index 0, etc.

    if (
      gearHardness < matlCoeffs[matlIndex][0] ||
      gearHardness > matlCoeffs[matlIndex][1]
    ) {
      document.getElementById("input-machinability").value = "Error";
      const errorMessage = `
Error
Material hardness is outside of range for material class.
Max range is ${matlCoeffs[matlIndex][0].toFixed(0)} to ${matlCoeffs[
        matlIndex
      ][1].toFixed(0)} BHN.`;

      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
      return;
    }

    var coeff = matlCoeffs[matlIndex];
    var resultMachinability =
      coeff[2] +
      coeff[3] * gearHardness ** 1 +
      coeff[4] * gearHardness ** 2 +
      coeff[5] * gearHardness ** 3 +
      coeff[6] * gearHardness ** 4 +
      coeff[7] * gearHardness ** 5 +
      coeff[8] * gearHardness ** 6 +
      coeff[9] * gearHardness ** 7 +
      coeff[10] * gearHardness ** 8;
    document.getElementById("input-machinability").value =
      resultMachinability.toFixed(2);
  }

  function calculate(event) {
    event.preventDefault();

    var gearModule = parseFloat(document.getElementById("input-module").value);
    // var precision = parseFloat(document.getElementById('precision').value);

    var hobDiameter = parseFloat(document.getElementById("input-hobdia").value);
    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var gearMachinability = parseFloat(
      document.getElementById("input-machinability").value
    );
    // for scallops
    var helixAngle = parseFloat(document.getElementById("input-helical").value);
    var pressureAngle = parseFloat(
      document.getElementById("input-pangle").value
    );
    var maxScallop = parseFloat(document.getElementById("input-scallop").value);

    logQA({ page: "GCSfands2", module: gearModule, param7: modWord });

    var checkGearModule = gearModule;
    var checkModuleIsModule = true;
    if (document.getElementsByName("units")[1].checked) {
      checkModuleIsModule = false;
      checkGearModule = 25.4 / gearModule;
    }

    var errorMessage = "Error:\n";
    var okToCalc = true;

    // if (isNaN(precision) || !(precision >= 0)){
    //   okToCalc = false
    //   errorMessage += "Precision must be 0 or greater\n"
    // }
    if (isNaN(gearModule) || gearModule <= 0) {
      okToCalc = false;
      errorMessage += "Module/DP must be positive\n";
    }

    if (checkModuleIsModule && checkGearModule > 14) {
      okToCalc = false;
      errorMessage += "Module must be less than 14\n";
    }
    if (checkModuleIsModule && checkGearModule < 0.5) {
      okToCalc = false;
      errorMessage += "Module must be at least 0.5\n";
    }
    if (!checkModuleIsModule && checkGearModule > 14) {
      okToCalc = false;
      errorMessage += "DP must be greater than 1.82\n";
    }
    if (!checkModuleIsModule && checkGearModule < 0.5) {
      okToCalc = false;
      errorMessage += "DP must be less than 53\n";
    }

    if (isNaN(hobDiameter) || hobDiameter <= 0) {
      okToCalc = false;
      errorMessage += "Hob diameter must be positive\n";
    }
    if (isNaN(numTeeth) || numTeeth <= 0) {
      okToCalc = false;
      errorMessage += "Number of teeth must be positive\n";
    }

    if (isNaN(gearMachinability) || gearMachinability <= 0) {
      okToCalc = false;
      errorMessage += "Gear machinability must be positive\n";
    }

    if (isNaN(pressureAngle) || pressureAngle <= 0) {
      okToCalc = false;
      errorMessage += "Pressure angle must be positive\n";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }

    return false;
  }
  function calculateMOW() {
    var radioValue;

    // get all units in metric (mm,module)

    var gearModule = parseFloat(document.getElementById("input-module").value);
    var hobDiameter = parseFloat(document.getElementById("input-hobdia").value);
    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var hobMaterialValue = document.getElementById("input-hobmatldrop").value;
    var gearHardness = parseFloat(
      document.getElementById("input-hardness").value
    );
    var gearMachinability = parseFloat(
      document.getElementById("input-machinability").value
    );
    // for scallops
    var helixAngle = parseFloat(document.getElementById("input-helical").value);
    var presureAngle = parseFloat(
      document.getElementById("input-pangle").value
    );
    var maxScallop = parseFloat(document.getElementById("input-scallop").value);

    var modelAdjust = parseFloat(document.getElementById("modeldrop").value);
    if (isNaN(modelAdjust) || !modelAdjust) {
      // Add the three (arbitrary) model adjustments
      modelAdjust = 1;
    }
    var modelText;
    if (modelAdjust === 1) modelText = "Model A";
    else if (modelAdjust === 0.875) modelText = "Model B";
    else if (modelAdjust === 0.75) modelText = "Model C";
    else modelText = "undefined";

    // var precision = parseFloat(document.getElementById('precision').value);
    if (gearMachinability <= 2) gearMachinability = gearMachinability * 100; // In case someone enters as decimal

    if (isNaN(helixAngle) || helixAngle < 0) helixAngle = 0;
    if (isNaN(maxScallop) || maxScallop < 0) maxScallop = 0;

    // convert hob material to coefficient
    var hobMaterial = 0;
    var hobMaterialCoeffs = [
      ["default", 1],
      ["M2", 800],
      ["M42 Coated", 1200],
      ["Carbide", 1500],
    ];

    for (let i = 0; i < hobMaterialCoeffs.length; i++) {
      if (hobMaterialCoeffs[i][0] === hobMaterialValue) {
        hobMaterial = hobMaterialCoeffs[i][1];
        break;
      }
    }
    if (hobMaterial === 0) {
      // No match found.
      hobMaterialValue = "default";
      hobMaterial = 1;
    }

    let unitWord = "mm";
    let moduleWord = "Module";
    let moduleInput = gearModule;
    let hobDiameterInput = hobDiameter;
    let maxScallopInput = maxScallop;

    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      unitWord = "in";
      gearModule = 25.4 / gearModule;
      hobDiameter = hobDiameter * 25.4;
      moduleWord = "Diametral Pitch";
      maxScallop = maxScallop * 25.4;
    } else {
      unitWord = "mm";
    }

    var finalSurfaceSpeed = hobMaterial / (gearHardness / 100) ** 2; // SFM
    var finalRPM = (finalSurfaceSpeed * 12) / ((Math.PI * hobDiameter) / 25.4); // RPM
    var roughFeed; // units are mm/rev
    var validRoughFeed = false;
    if (gearModule >= 0.5 && gearModule <= 1.43) {
      // Eq 1-2
      roughFeed = -2.5 + 6 * gearModule;
      validRoughFeed = true;
    } else if (gearModule > 1.43 && gearModule <= 14) {
      // Eq 2-4
      roughFeed = 6.2229 - 0.0857 * gearModule;
      validRoughFeed = true;
    } else {
      // No feed.
      roughFeed = "Error; module out of bounds.";
    }

    // Adjustment Factor C1
    var factorC1 = (gearMachinability / 70) ** 0.4;

    // Adjustment Factor C2 (if less than 25 teeth)
    var factorC2 = 1;
    if (numTeeth < 25) {
      factorC2 = (numTeeth / 25) ** 0.3;
    }

    var adjustedRoughFeed = roughFeed * factorC1 * factorC2;

    // Max Feed for Scallops
    var maxFeed =
      Math.cos((Math.PI * helixAngle) / 180) *
      Math.sqrt(
        ((maxScallop / 25.4) * 4 * hobDiameter) /
          25.4 /
          Math.sin((Math.PI * presureAngle) / 180)
      );

    // Clamp max feed rate (by scallops) to rough feed rate
    if (maxFeed * 25.4 > adjustedRoughFeed) {
      maxFeed = adjustedRoughFeed / 25.4;
    }

    // Adjust for Speed Model
    finalSurfaceSpeed = finalSurfaceSpeed * modelAdjust;
    finalRPM = finalRPM * modelAdjust;

    var textToWrite = "Inputs";
    textToWrite += "\nTooth Count:\t" + numTeeth;
    textToWrite += "\n" + moduleWord + ":\t" + moduleInput;
    textToWrite += "\nPressure Angle:\t" + presureAngle + "\tdegrees";
    textToWrite += "\nHelix Angle:\t" + helixAngle + "\tdegrees";
    textToWrite +=
      "\nHob Material:\t" + document.getElementById("input-hobmatldrop").value;
    textToWrite += "\nHob Diameter:\t" + hobDiameterInput + "\t" + unitWord;
    textToWrite += "\nMax Scallop:\t" + maxScallopInput + "\t" + unitWord;
    textToWrite += "\nMachinability:\t" + Math.floor(gearMachinability) + "\t%";
    textToWrite += "\nSpeed Model:\t" + modelText;

    textToWrite += "\n\nOutputs";
    textToWrite +=
      "\nSurface Meters/Min (SMM):\t" +
      (finalSurfaceSpeed / 3.28).toFixed(1) +
      "\tm/min";
    textToWrite +=
      "\nSurface Feet/Min   (SFM):\t" +
      finalSurfaceSpeed.toFixed(1) +
      "\tft/min";
    textToWrite +=
      "\nRevolutions per Minute (RPM):\t" + Math.floor(finalRPM) + "\trpm";
    textToWrite += "\n";
    textToWrite +=
      "\nRough Feed Rate:\t" +
      adjustedRoughFeed.toFixed(2) +
      "\tmm/rev\t" +
      (adjustedRoughFeed / 25.4).toFixed(2) +
      "\tin/rev";
    textToWrite +=
      "\nMax Feed Rate:\t" +
      (maxFeed * 25.4).toFixed(2) +
      "\tmm/rev\t" +
      maxFeed.toFixed(2) +
      "\tin/rev";
    textToWrite +=
      Math.min(adjustedRoughFeed / 25.4, maxFeed) < 0.04
        ? "\nNote:\tFeed <0.04 in/rev not recommended"
        : "";
    textToWrite +=
      "\n\n\u00A9 " +
      new Date().getFullYear() +
      " Evolvent Design,\t" +
      formatDate(new Date()) +
      "\nGCS0";

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);

    return false;
  }

  return (
    <>
      <Card className="project-card">
        <div className="project-name">
          Traditional Feed and Speed (Model II)
        </div>
        <p>
          This model uses the older and more traditional M2, M4 and Carbide hob
          materials.
        </p>
        <p>
          At its heart, the model is based on machinability of the gear material
          which is usually a function of hardness.
        </p>
        <p>
          The model produces a hob speed as well as two different feed rates,
          one roughing and one which is a maximum based on the allowed scallop
          size (finishing).
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="single"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={calculate}
              userLevel={userLevel}
            />
            <LoadSavedGear
              reqRecordType="hob"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={calculate}
              buttonText="Load Hob"
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={7}>
            <form className="calculator">
              <UnitsRadio />

              <div className="bluebox">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="modeldrop">Speed Model</label>
                  <select className="inputbox" name="modeldrop" id="modeldrop">
                    <option value="default">Model...</option>
                    <option value="1">Model A</option>
                    <option value="0.875">Model B</option>
                    <option value="0.75">Model C</option>
                  </select>
                </div>

                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-numteeth">Gear Toothcount</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-numteeth"
                    size="25"
                    placeholder="Number of teeth"
                  />
                </div>

                <DPModuleInput />

                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-pangle">Pressure Angle</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-pangle"
                    size="25"
                    placeholder="Pressure Angle"
                  />
                </div>

                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-scallop">Max Scallop Height</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-scallop"
                    size="25"
                    placeholder="Max Scallop Height"
                  />
                </div>

                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-helical">Helix Angle</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-helical"
                    size="25"
                    placeholder="Helix Angle"
                  />
                </div>

                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-hardness">
                    Material Hardness (BHN)
                    <div className="calctooltip">
                      [?]
                      <span className="tooltiptext">
                        BHN is Brinell Hardness Number
                      </span>
                    </div>
                  </label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-hardness"
                    size="25"
                    placeholder="Hardness (BHN)"
                    onChange={updateGearMaterial}
                  />
                </div>

                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-matldrop">Material Class</label>
                  <select
                    className="inputbox"
                    name="input-matldrop"
                    id="input-matldrop"
                    onChange={updateGearMaterial}
                  >
                    <option value="default">Gear material...</option>
                    <option value="1">
                      Carbon Steels (10xx, 11xx, 12xx, 15xx)
                    </option>
                    <option value="2">Low alloy chrome steels</option>
                    <option value="3">Nickel Steels</option>
                    <option value="4">Chrome-nickel Steels</option>
                    <option value="5">
                      High Alloy Chr-N Steel (31xx, 33xx, 303xx Stainless)
                    </option>
                    <option value="6">
                      Chr-Ni-Moly (43xx, 47xx, 86xx, 87xx, 93xx, 98xx)
                    </option>
                    <option value="7">Ni-Moly Steels (46xx, 48xx)</option>
                    <option value="8">Chrome-Moly Steels (41xx)</option>
                    <option value="9">
                      Chrome Steels (50xx, 51xx, 511xx, 521xx, 515xx)
                    </option>
                    <option value="10">Chrome-Vanadium Steels (61xx)</option>
                    <option value="11">Silicon-Manganese Steels (92xx)</option>
                    <option value="12">Ductile Iron</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-machinability">
                    Material Machinability
                    <div className="calctooltip">
                      [?]
                      <span className="tooltiptext">Enter as % (e.g. 70)</span>
                    </div>
                  </label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-machinability"
                    size="25"
                    placeholder="Needs material and hardness"
                  />
                </div>
              </div>

              <div className="bluebox">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-hobmatldrop">Hob Material</label>
                  <select
                    className="inputbox"
                    name="input-hobmatldrop"
                    id="input-hobmatldrop"
                  >
                    <option value="default">Hob material...</option>
                    <option value="M2">M2</option>
                    <option value="M42 Coated">M42 Coated</option>
                    <option value="Carbide">Carbide</option>
                  </select>
                </div>

                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-numteeth">Hob Diameter</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-hobdia"
                    size="25"
                    placeholder="Hob Diameter"
                  />
                </div>
              </div>

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />

              {userLevel > 0 && (
                <>
                  <SaveButtonBlock
                    project={project}
                    setProject={setProject}
                    saveInputsHandler={saveInputsHandler}
                    saveMessage={saveMessage}
                  />

                  <SaveButtonBlock
                    project={project}
                    setProject={setProject}
                    saveInputsHandler={saveInputsHandlerHob}
                    inputText={"Hob Name"}
                    inputId={"input-hobname"}
                    buttonText={"Save Hob"}
                    saveMessage={saveMessageHob}
                  />
                </>
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={5}>
            <Image src="/calc-images/fands-1.jpg" fluid /> <br />
            <Image src="/calc-images/fands-2.jpg" fluid /> <br />
            <Image src="/calc-images/fands-3.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          This model also has fidelity for pressure angle, tooth count and
          helical gears for the feed rate recommendations.
        </p>
        <p>
          This model is a guide for roughing speeds and feeds. Hobs and machine
          capabilities have a lot to do with your individual results. The user
          should keep within the safe operating limits of their machinery when
          hobbing, and use hobs that are sharp and in good condition.
        </p>
        <p>
          Speed Calculation in existing model
          <ul>
            <li>
              A Model results are for new rigid CNC machines and for maximum
              material removal in ideal conditions. This model may result
              shorter tool life
            </li>
            <li>
              B Model results are for used but rigid CNC machines, or older
              rebuilt and higher quality machines
            </li>
            <li>
              C Model results are for pre-75 machines with lower RPM
              capabilities and both age and rigidity derate operational speeds.
            </li>
          </ul>
          The user should experiment with this and match it to their machine.
          <p>Values for Speeds are good down to 1 module.</p>
        </p>
      </Card>
    </>
  );
}
