import React from "react";

import Card from "react-bootstrap/Card";

export default function UtilityReference() {
  return (
    <Card className="project-card">
      <div className="project-name" style={{ marginBottom: "40px" }}>
        Reference Documents
      </div>
      <a href="/documents/NASA_Gearing_85.pdf">NASA Gearing, 1985</a>
      <a href="/documents/NASA_Fasteners_90.pdf">NASA Fasteners, 1990</a>
      <a href="/documents/NASA_Gear_Theory_98.pdf">NASA Gear Theory, 1990</a>
    </Card>
  );
}
