import React from "react";

import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import {
  UnitsRadio,
  DecimalPlacesSelect,
  DPModuleInput,
  ButtonBlockTextArea,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorVariRoll({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  function clearInputs(event) {
    document.getElementById("input-center").value = "";
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-numteeth2").value = "";
    document.getElementById("input-shaft1").value = "";
    document.getElementById("input-shaft2").value = "";
    document.getElementById("input-module").value = "";
    document.getElementById("input-testdia").value = "";
    document.getElementById("input-module2").value = "";
    document.getElementById("input-numteeth3").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  var precision;

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    var numTeeth1 = parseFloat(document.getElementById("input-numteeth").value);
    var numTeeth2 = parseFloat(document.getElementById("input-numteeth2").value);
    var numTeeth3 = parseFloat(document.getElementById("input-numteeth3").value);
    var shaft1 = parseFloat(document.getElementById("input-shaft1").value);
    var shaft2 = parseFloat(document.getElementById("input-shaft2").value);
    var centerDistance = parseFloat(
      document.getElementById("input-center").value
    );
    var testDia = parseFloat(document.getElementById("input-testdia").value);
    var module = document.getElementById("input-module").value;
    var module2 = document.getElementById("input-module2").value;
    var precision = document.getElementById("precision").value;

    let conversionFactor = 1;
    if (radioValue === "in") {
      module = 25.4 / module;
      module2 = 25.4 / module2;
      conversionFactor = 25.4;
    }

    logQA({
      page: "GCSvariroll",
      numteeth: numTeeth1,
      module: module,
      param5: numTeeth2,
      param6: radioValue,
    });
    var errorMessage = "Error";
    var okToCalc = true;
    var useCenterDistance = false;
    var useMixedCalc = false;

    if (isNaN(shaft1) || isNaN(shaft2) || shaft1 <= 0 || shaft2 <= 0) {
      okToCalc = false;
      errorMessage = "Both shaft diameters must be defined and greater than 0";
    }

    if (centerDistance) {
      useCenterDistance = true;
    }
    if (testDia) {
      useMixedCalc = true;
    }

    if (useCenterDistance && useMixedCalc) {
      okToCalc = false;
      errorMessage = "Specify either center distance or geometry, not both";
    }

    if (useCenterDistance && centerDistance <= (shaft1 + shaft2) / 2) {
      okToCalc = false;
      errorMessage = "Center distance is too close for given shaft sizes";
      errorMessage += "\nReduce shaft size(s) or increase center distance";
    }

    if (useMixedCalc && !Number.isInteger(numTeeth3)) {
      okToCalc = false;
      errorMessage = "Number of teeth on test gear must be an integer";
    }

    if (useMixedCalc && !(module2 >= 0.1 && module2 <= 256)) {
      okToCalc = false;
      errorMessage = "Module/DP must be between 0.1 and 256";
    }

    if (useMixedCalc && (!Number.isFinite(testDia) || testDia <= 0)) {
      okToCalc = false;
      errorMessage = "Test diameter must be a positive number";
    }

    if (
      useMixedCalc &&
      shaft1 + shaft2 >= (numTeeth3 * module2) / conversionFactor + testDia
    ) {
      okToCalc = false;
      errorMessage = "Center distance is too close for given shaft sizes";
      errorMessage += "\nReduce shaft size(s) or increase gear size";
    }

    if (!useCenterDistance && !useMixedCalc && !Number.isInteger(numTeeth1)) {
      okToCalc = false;
      errorMessage = "Number of teeth on Gear 1 must be an integer";
    }
    if (!useCenterDistance && !useMixedCalc && !Number.isInteger(numTeeth2)) {
      okToCalc = false;
      errorMessage = "Number of teeth on Gear 2 must be an integer";
    }
    if (
      !useCenterDistance &&
      !useMixedCalc &&
      !(module >= 0.1 && module <= 256)
    ) {
      okToCalc = false;
      errorMessage = errorMessage + "\nModule/DP must be between 0.1 and 256";
    }

    if (
      !useCenterDistance &&
      !useMixedCalc &&
      shaft1 + shaft2 >= (numTeeth1 + numTeeth2) * module
    ) {
      okToCalc = false;
      errorMessage = "Center distance is too close for given shaft sizes";
      errorMessage += "\nReduce shaft size(s) or increase gear size";
    }

    if (!(precision >= 0 && precision <= 8)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of Decimal Places must be between 0 and 8";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }
  }
  function calculateMOW() {
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    var shaft1 = parseFloat(document.getElementById("input-shaft1").value);
    var shaft2 = parseFloat(document.getElementById("input-shaft2").value);
    var centerDistance = parseFloat(
      document.getElementById("input-center").value
    );
    var testDia = parseFloat(document.getElementById("input-testdia").value);

    var z1 = parseFloat(document.getElementById("input-numteeth").value);
    var z2 = parseFloat(document.getElementById("input-numteeth2").value);
    var z3 = parseFloat(document.getElementById("input-numteeth3").value);
    var m_n = parseFloat(document.getElementById("input-module").value);
    var m_n2 = parseFloat(document.getElementById("input-module2").value);

    var modWord, okBlocks, textToWrite;

    if (!m_n && m_n2) {
      m_n = m_n2; // if the second module box is selected, use that one
    }
    precision = document.getElementById("precision").value;
    var m_nInput = m_n;
    if (radioValue === "in") {
      m_n = 25.4 / m_n;

      modWord = "Diametral Pitch";
      shaft1 = shaft1 * 25.4;
      shaft2 = shaft2 * 25.4;
      centerDistance = centerDistance * 25.4;
      testDia = testDia * 25.4;
    } else {
      modWord = "Module";
    }

    // Calculate a center distance is none is provided
    let calcCD = false;
    let mixedCD = false;
    if (!centerDistance && !testDia) {
      calcCD = true;
      centerDistance = ((z1 + z2) * m_n) / 2;
    }

    if (testDia && !centerDistance) {
      mixedCD = true;
      centerDistance = (testDia + z3 * m_n) / 2;
    }
    // Calculate gap between the shafts
    let shaftGap = centerDistance - shaft1 / 2 - shaft2 / 2;
    let shaftGapBlocks = getGaugeBlocks(shaftGap / 25.4);
    okBlocks = true;
    if (shaftGapBlocks.length === 0) {
      okBlocks = false;
    }

    textToWrite = `Inputs
Diameter Shaft 1:\t${shaft1.toFixed(precision)}\tmm\t${(shaft1 / 25.4).toFixed(
      precision
    )}\tin
Diameter Shaft 2:\t${shaft2.toFixed(precision)}\tmm\t${(shaft2 / 25.4).toFixed(
      precision
    )}\tin`;
    if (!calcCD && !mixedCD) {
      textToWrite += `
Center Distance:\t ${centerDistance.toFixed(precision)}\tmm\t${(
        centerDistance / 25.4
      ).toFixed(precision)}\tin`;
    } else if (calcCD && !mixedCD) {
      textToWrite += `
Number of Teeth (Gear 1):\t${z1}
Number of Teeth (Gear 2):\t${z2}
${modWord}:\t${m_nInput}`;
    } else {
      textToWrite += `
Test Diameter:\t${testDia.toFixed(precision)}\tmm\t${(testDia / 25.4).toFixed(
        precision
      )}\tin
Number of Teeth (Gear):\t${z3}
${modWord}:\t${m_nInput}`;
    }
    textToWrite += `

Outputs
Center Distance:\t   ${centerDistance.toFixed(precision)}\tmm\t${(
      centerDistance / 25.4
    ).toFixed(precision)}\tin
Gap Between Shafts:\t${shaftGap.toFixed(precision)}\tmm\t${(
      shaftGap / 25.4
    ).toFixed(precision)}\tin
Suggested (inch) Blocks:\t`;
    if (okBlocks) {
      textToWrite += shaftGapBlocks.join(", ");
    } else {
      textToWrite += "Out of range (0.3 to 8 inches)";
    }
    textToWrite += `

\u00A9 ${new Date().getFullYear()} Evolvent Design, ${formatDate(new Date())}
GCS0`;

    // OLD Output
    //  "+ "\nPitch Diameter:\t" + pitchDiameter.toFixed(precision) + "\tmm\t" + (pitchDiameter/25.4).toFixed(precision) + "\tin"+ "\nBase Diameter: \t" + baseCircle.toFixed(precision) + "\tmm\t" + (baseCircle/25.4).toFixed(precision) +"\tin"+ "\nRoot Diameter: \t" + rootDiameter.toFixed(precision) + "\tmm\t" + (rootDiameter/25.4).toFixed(precision) +"\tin" +"\n\nAddendum:       \t" + addendum.toFixed(precision) + "\tmm\t" + (addendum/25.4).toFixed(precision) +"\tin"+ "\nDedendum:       \t" + dedendum.toFixed(precision) + "\tmm\t" + (dedendum/25.4).toFixed(precision) +"\tin"+ "\nWorking Depth:  \t" + workingDepth.toFixed(precision) + "\tmm\t" + (workingDepth/25.4).toFixed(precision) +"\tin"+ "\nWhole Depth:    \t" + wholeDepth.toFixed(precision) + "\tmm\t" + (wholeDepth/25.4).toFixed(precision) +"\tin"

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  function getGaugeBlocks(inputGaugeLength) {
    var gaugeLength = parseFloat(inputGaugeLength);
    let startingGaugeLength = gaugeLength;
    if (gaugeLength <= 0.3 || gaugeLength > 8) {
      return [];
    }

    /*
(9) Blocks: .1001" to .1009" (.0001" steps),
(49) Blocks: .101" to .149 (.001" steps),
(19) Blocks: .05" to .95" (.05" steps),
(1) Each of blocks: 1", 2", 3", 4"
*/

    /*
Nine blocks in sizes 0.1001" to 0.1009" in 0.0001" increments
Nine blocks in sizes 0.101" to 0.109" in 0.001" increments
One block each in sizes 0.200", 0.300", 0.500", 1.000", 2.000", and 4.000"
One block in size 0.10005"
Ten blocks in sizes 0.100" to 0.190" in 0.01" increments
*/

    let blocksA = [];
    let blocksB = [];
    gaugeLength = Math.floor(gaugeLength * 10000) / 10000; // round to tenths by truncation

    // First we eliminate the tenths
    let tenths = gaugeLength - Math.floor(gaugeLength * 1000) / 1000;
    let tenthsBlock = 0.1 + tenths;
    if (tenths > 0) {
      blocksA.push(tenthsBlock.toFixed(4));
      blocksB.push(tenthsBlock.toFixed(4));
      gaugeLength = gaugeLength - tenthsBlock;
    }
    // Now the thousandths
    // for set B
    let thous = gaugeLength - Math.floor(gaugeLength * 100) / 100;
    if (thous > 0) {
      let thousBlock = 0.1 + thous;
      blocksB.push(thousBlock.toFixed(4));
      gaugeLength = gaugeLength - thousBlock;
    }

    // Now the hundredths
    // for set B
    let hundreths = gaugeLength - Math.floor(gaugeLength * 10) / 10;
    if (hundreths > 0) {
      let hundsBlock = 0.1 + hundreths;
      blocksB.push(hundsBlock.toFixed(4));
      gaugeLength = gaugeLength - hundsBlock;
    }

    // Now the bulk
    const gaugeBlocksB = [4, 2, 1, 0.5, 0.3, 0.2, 0.1];
    for (let i = 0; i < gaugeBlocksB.length; i++) {
      if (gaugeLength.toFixed(4) >= gaugeBlocksB[i].toFixed(4)) {
        gaugeLength = gaugeLength - gaugeBlocksB[i];
        blocksB.push(gaugeBlocksB[i].toFixed(4));
        gaugeBlocksB.splice(i, 1);
        i--;
      }
    }

    let sumGaugeBlocks = 0;
    for (let i = 0; i < blocksB.length; i++) {
      sumGaugeBlocks += parseFloat(blocksB[i]);
    }
    return blocksB.sort((a, b) => b - a);
  }

  return (
    <>
      <Card className="project-card">
        <div className="project-name">Vari-Roll</div>
        <p>
          Calculate the test spacing (center distance) for two gears on a
          Vari-Roll (or equivalent) double flank roll tester
        </p>
        <p>
          This calculator requires the two shaft sizes and then either a test
          center distance, or the geometry of the gear pair, to provide the
          measurable spacing between the shafts. Finally, if the output spacing
          is between 0.3" and 8.0", the calculator will provide a gauge block
          stackup based on commonly available sizes.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio />
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-shaft1">
                  Shaft Size (Gear 1)<span className="required">*</span>
                </label>
                <input className="inputbox" type="number" id="input-shaft1" />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-shaft2">
                  Shaft Size(Gear 2)<span className="required">*</span>
                </label>
                <input className="inputbox" type="number" id="input-shaft2" />
              </div>
              <div
                style={{
                  margin: "30px 0 10px",
                  borderBottom: "1px solid lightgray",
                }}
              >
                Enter fixed center distance
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-center">Center Distance</label>
                <input className="inputbox" type="number" id="input-center" />
              </div>
              <div
                style={{
                  margin: "30px 0 10px",
                  borderBottom: "1px solid lightgray",
                }}
              >
                Or calculate center distance from gear geometry
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">Number of Teeth (Gear 1)</label>
                <input className="inputbox" type="number" id="input-numteeth" />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth2">Number of Teeth (Gear 2)</label>
                <input className="inputbox" type="number" id="input-numteeth2" />
              </div>
              <DPModuleInput />
              <div
                style={{
                  margin: "30px 0 10px",
                  borderBottom: "1px solid lightgray",
                }}
              >
                Or calculate a combination
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-testdia">Master Gear Test Diameter</label>
                <input className="inputbox" type="number" id="input-testdia" />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth3">Number of Teeth (Gear)</label>
                <input className="inputbox" type="number" id="input-numteeth3" />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-module2">
                  DP/Module*
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Module is in millimeters, DP is in 1/inch
                    </span>
                  </div>
                </label>
                <input className="inputbox" type="number" id="input-module2" />
              </div>

              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/variroll-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          Gauge blocks are calculated using this kit from{" "}
          <a href="https://www.mcmaster.com/2210A84/">McMaster</a> with:
          <ul>
            <li>1pc, 0.10005"</li>
            <li>9pc, 0.1001-0.1009" in 0.0001" increments</li>
            <li>9pc, 0.101-0.109" in 0.001" increments</li>
            <li>10pc, 0.100-0.190" in 0.01" increments</li>
            <li>1 each: 0.200", 0.300", 0.500", 1.000", 2.000", 4.000"</li>
          </ul>
        </p>
      </Card>
    </>
  );
}
