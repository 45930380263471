import React from "react";
import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import {
  UnitsRadio,
  DPModuleInput,
  DecimalPlacesSelect,
  ButtonBlockTextArea,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorCenterDistance({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  function clearInputs(event) {
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-numteeth2").value = "";
    document.getElementById("input-module").value = "";
    setTextAreaText("");

    event.preventDefault();
    return false;
  }

  // Start actual calculator

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    var numTeeth1 = parseFloat(document.getElementById("input-numteeth").value);
    var numTeeth2 = parseFloat(document.getElementById("input-numteeth2").value);
    var module = document.getElementById("input-module").value;
    var precision = document.getElementById("precision").value;

    logQA({
      page: "GCScenterDistance",
      numteeth: numTeeth1,
      module: module,
      param5: numTeeth2,
      param6: radioValue,
    });
    var errorMessage = "Error";
    var okToCalc = true;
    if (!(numTeeth1 >= 8 && numTeeth1 <= 368)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of teeth on Gear 1 must be between 8 and 368";
    }
    if (!(numTeeth2 >= 8 && numTeeth2 <= 368)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of teeth on Gear 2  must be between 8 and 368";
    }
    if (!Number.isInteger(numTeeth1)) {
      okToCalc = false;
      errorMessage = "Number of teeth on Gear 1 must be an integer";
    }
    if (!Number.isInteger(numTeeth2)) {
      okToCalc = false;
      errorMessage = "Number of teeth on Gear 2 must be an integer";
    }
    if (!(module >= 0.1 && module <= 256)) {
      okToCalc = false;
      errorMessage = errorMessage + "\nModule/DP must be between 0.1 and 256";
    }
    if (!(precision >= 0 && precision <= 8)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of Decimal Places must be between 0 and 8";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer();
    }
  }
  function calculateMOW() {
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }

    var z1 = parseFloat(document.getElementById("input-numteeth").value);
    var z2 = parseFloat(document.getElementById("input-numteeth2").value);
    var m_n = parseFloat(document.getElementById("input-module").value);
    var precision = document.getElementById("precision").value;
    var m_nInput = m_n;
    var unitConversion;
    var modWord;

    if (radioValue === "in") {
      m_n = 25.4 / m_n;
      modWord = "Diametral Pitch";
      unitConversion = 1;
    } else {
      modWord = "Module";
      unitConversion = 25.4;
    }

    // Calculate output
    var pitchDiameter1 = (m_n * z1 * unitConversion) / 25.4;
    var pitchDiameter2 = (m_n * z2 * unitConversion) / 25.4;
    var centerDistance = (pitchDiameter1 + pitchDiameter2) / 2;

    if (radioValue === "in") {
      // reset units to millimeters (to display both)
      pitchDiameter1 = pitchDiameter1 * 25.4;
      pitchDiameter2 = pitchDiameter2 * 25.4;
      centerDistance = centerDistance * 25.4;
    }

    var textToWrite = `Inputs
${modWord}:\t${m_nInput}
Number of Teeth (Gear 1):\t${z1}
Number of Teeth (Gear 2):\t${z2}

Outputs
Center Distance:\t${centerDistance.toFixed(precision)}\tmm\t${(
      centerDistance / 25.4
    ).toFixed(precision)}\tin

\u00A9${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}
GCS0`;

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <div className="project-name">Center Distance</div>
        <p>
          <span className="term-emphasis">Center Distance</span> is the distance
          between the centers of two cylindrical gears in mesh, and is defined
          as the half the sum of each gear's pitch diameter.
        </p>
        <p>
          The center distance is important when designing the gearcase to ensure
          the gears will mesh properly.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Teeth (Gear 1)
                  <span className="required">*</span>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-numteeth"
                  size="35"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Teeth (Gear 2)
                  <span className="required">*</span>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-numteeth2"
                  size="35"
                />
              </div>

              <DPModuleInput />

              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/centerdistance-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          For standard gears the center distance is set so the pitch diameters
          are tangent at a single point, regardless of small profile shifts.
        </p>
        <p>
          Small profile shifts do not change the pitch diameter required for
          gears to mesh properly.
        </p>
      </Card>
    </>
  );
}
