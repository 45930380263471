// Import Bootstrap style elements
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Footer from "./footer";
import PublicHeader from "./header";
import ComparePlansTable from "./plan-table";

import React, { useState } from "react"; // For collapsing
import Collapse from "react-bootstrap/Collapse";

export default function PublicPricingPage() {
  const [openCalculators1, setOpenCalculators1] = useState(false);
  const [openCalculators2, setOpenCalculators2] = useState(false);
  const [openCalculators3, setOpenCalculators3] = useState(false);
  const [openCalculators4, setOpenCalculators4] = useState(false);
  const [openCalculators5, setOpenCalculators5] = useState(false);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
        <Col>
          <PublicHeader />

          <Row>
            <h2 className="card-heading">pricing</h2>

            <div className="row row-cols-1 row-cols-md-3 mb-3 text-center p-0">
              <div className="col">
                <div className="card mb-4 rounded-3 shadow-sm">
                  <div className="card-header py-3">
                    <h4 className="my-0 fw-normal">Free</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">
                      $0
                      <small className="text-body-secondary fw-light">
                        /mo
                      </small>
                    </h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>5+ calculators</li>
                      <li>Support for spur gears</li>
                    </ul>
                    <a
                      className="btn btn-lg w-100 btn-outline-primary"
                      href="/join"
                      role="button"
                    >
                      Sign up for free
                    </a>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 rounded-3 shadow-sm">
                  <div className="card-header py-3">
                    <h4 className="my-0 fw-normal">Lite</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">
                      $12
                      <small className="text-body-secondary fw-light">
                        /mo
                      </small>
                    </h1>
                    <h4 className="card-title pricing-card-title">
                      $120
                      <small className="text-body-secondary fw-light">
                        /yr save 15%
                      </small>
                    </h4>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>18 Calculators</li>
                      <li>Support for spur gears</li>
                      <li>Store and save gears</li>
                      <li>Save and manage projects</li>
                    </ul>
                    <a
                      className="btn btn-lg w-100 btn-primary"
                      href="/join"
                      role="button"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 rounded-3 shadow-sm border-primary pricing-card-primary">
                  <div className="card-header py-3 pricing-card-header border-primary">
                    <h4 className="my-0 fw-normal">Pro</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">
                      $50
                      <small className="text-body-secondary fw-light">
                        /mo
                      </small>
                    </h1>
                    <h4 className="card-title pricing-card-title">
                      $300
                      <small className="text-body-secondary fw-light">
                        /yr save 50%
                      </small>
                    </h4>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>All 23 calculators</li>
                      <li>Support for spur and helical gears</li>
                      <li>Support for worm gears</li>
                      <li>Store and save gears</li>
                      <li>Save and manage projects</li>
                    </ul>
                    <a
                      className="btn btn-lg w-100 btn-primary"
                      href="/join"
                      role="button"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <Card className="project-card">
              <div className="project-name">
                <h2
                  style={{ fontSize: "1.2rem", fontWeight: "bold", margin: 0 }}
                >
                  GearCalculators Value
                </h2>
              </div>

              <div style={{ maxWidth: "800px" }}>
                <p>
                  GearCalculators.com offers a comprehensive suite of
                  calculators for a fraction of the price!
                </p>
                <p>
                  Most gear software is expensive, we've bought and tested
                  enough of it to know. Simple packages might be a $600-$1200
                  dollars while a professional easily exceeds $10,000. While
                  this makes sense for certain (automobile-building) companies,
                  it's simply too much for the average machine shop, average
                  hobbyist, or gear enthusiast.
                </p>
                <p>
                  We've built our most commonly used tools into this website and
                  offer them at an accessible price.
                </p>
              </div>
              <h3 className="display-8 mt-4 mb-4">Compare Plans</h3>

              <ComparePlansTable />
            </Card>
            <Card className="project-card">
              <div className="project-name">
                <h2
                  style={{ fontSize: "1.2rem", fontWeight: "bold", margin: 0 }}
                >
                  List of Calculators
                </h2>
              </div>

              <button
                onClick={() => setOpenCalculators1(!openCalculators1)}
                aria-controls="example-collapse-text"
                aria-expanded={openCalculators1}
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth"
                data-bs-toggle="collapse"
                data-bs-target="#account-collapse"
              >
                Gear Design
              </button>
              <Collapse in={openCalculators1}>
                <div className="table-responsive">
                  <table className="table calculator-table">
                    <tbody>
                      <tr>
                        <td>Dimensions</td>
                        <td>
                          Calculate the geometry of key dimensions for your
                          external or internal spur gear
                        </td>
                        <td>Lite for storage</td>
                      </tr>
                      <tr>
                        <td>Ratio</td>
                        <td>
                          Calculate the speed and mechanical advantage of a gear
                          system of cylindrical (spur, helix, etc.) gears
                        </td>
                        <td>Free</td>
                      </tr>
                      <tr>
                        <td>Center Distance</td>
                        <td>
                          Calculate the distance between the centers of two
                          cylindrical gears in mesh
                        </td>
                        <td>Free</td>
                      </tr>
                      <tr>
                        <td>Pitch Conversion</td>
                        <td>
                          Convert between standard pitch systems: Module,
                          Diametral Pitch, and Circular Pitch
                        </td>
                        <td>Free</td>
                      </tr>
                      <tr>
                        <td>Strength (Lewis)</td>
                        <td>
                          Calculate the strength of a gear tooth using the Lewis
                          Form Factor method
                        </td>
                        <td>Lite for storage</td>
                      </tr>
                      <tr>
                        <td>Strength (Dudley)</td>
                        <td>
                          Calculate the strength of a gear using its geometry,
                          material, RPM, manufacturing methods and load
                          conditions, using the Dudley method
                        </td>
                        <td>Free</td>
                      </tr>
                      <tr>
                        <td>Lead Angle (Worms, Hobs)</td>
                        <td>Calculate the lead angle for hobs and worms</td>
                        <td>Free</td>
                      </tr>
                      <tr>
                        <td>Lead Angle (Threads)</td>
                        <td>Calculate the lead angle for threads</td>
                        <td>Free</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Collapse>
              <button
                onClick={() => setOpenCalculators2(!openCalculators2)}
                aria-controls="example-collapse-text"
                aria-expanded={openCalculators2}
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth"
                data-bs-toggle="collapse"
                data-bs-target="#account-collapse"
              >
                Gear Generators
              </button>
              <Collapse in={openCalculators2}>
                <div className="table-responsive">
                  <table className="table calculator-table">
                    <tbody>
                      <tr>
                        <td>DXF Generator</td>
                        <td>
                          Generate a dimenstionally-accurate DXF or SVG file for
                          an external spur gear, internal spur gear, or gear
                          rack, with adjustable profile shift
                        </td>
                        <td>Lite</td>
                      </tr>
                      <tr>
                        <td>STL Generator</td>
                        <td>
                          Generate a dimensionally-accurate 3D model (.STL file)
                          for an external spur gear, internal spur gear, or gear
                          rack, with adjustable profile shift
                        </td>
                        <td>Lite</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Collapse>
              <button
                onClick={() => setOpenCalculators3(!openCalculators3)}
                aria-controls="example-collapse-text"
                aria-expanded={openCalculators3}
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth"
                data-bs-toggle="collapse"
                data-bs-target="#account-collapse"
              >
                Gear Ratio Design
              </button>
              <Collapse in={openCalculators3}>
                <div className="table-responsive">
                  <table className="table calculator-table">
                    <tbody>
                      <tr>
                        <td>Change Gears for Machinery</td>
                        <td>
                          Output all possible change gear combinations, for a
                          given tooth count and machine constant, using only the
                          range of change gears provided
                        </td>
                        <td>
                          <div
                            style={{
                              fontWeight: "600",
                              textAlign: "left",
                            }}
                          >
                            Change Gears Limits
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            Free <span>20-90</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            Lite <span>20-200</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            Pro <span>Unlimited</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Change Gears Brute-Force (multi-solution)</td>
                        <td>
                          Output all possible change gear combinations, for an
                          arbitrary ratio, within a specified tolerance, using
                          only the change gears provided
                        </td>
                        <td>Lite</td>
                      </tr>
                      <tr>
                        <td>Change Gears Optimized (unique solution)</td>
                        <td>
                          Quickly output one possible change gear combination,
                          for an arbitrary ratio, within a specified tolerance,
                          using only the change gears ranges provided
                        </td>
                        <td>Pro</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Collapse>
              <button
                onClick={() => setOpenCalculators4(!openCalculators4)}
                aria-controls="example-collapse-text"
                aria-expanded={openCalculators4}
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth"
                data-bs-toggle="collapse"
                data-bs-target="#account-collapse"
              >
                Gear Manufacturing
              </button>
              <Collapse in={openCalculators4}>
                <div className="table-responsive">
                  <table className="table calculator-table">
                    <tbody>
                      <tr>
                        <td>Hobbing Feeds & Speeds (Model&nbsp;I)</td>
                        <td>
                          Calculate cutter speed using the size, material of the
                          hob and gear, and other factors
                        </td>
                        <td>Pro</td>
                      </tr>
                      <tr>
                        <td>Hobbing Feeds & Speeds (Model&nbsp;II)</td>
                        <td>
                          Calculate the cutting speed and feed using the gear
                          geometry, material of the hob and gear, scallop
                          tolerance, and other factors
                        </td>
                        <td>Pro</td>
                      </tr>
                      <tr>
                        <td>Worm Carbide</td>
                        <td>
                          Calculate the carbide cutter geometry to make a
                          wormgear on a lathe
                        </td>
                        <td>Pro</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Collapse>
              <button
                onClick={() => setOpenCalculators5(!openCalculators5)}
                aria-controls="example-collapse-text"
                aria-expanded={openCalculators5}
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth"
                data-bs-toggle="collapse"
                data-bs-target="#account-collapse"
              >
                Gear Inspection
              </button>
              <Collapse in={openCalculators5}>
                <div className="table-responsive">
                  <table className="table calculator-table">
                    <tbody>
                      <tr>
                        <td>Measurement over Pins</td>
                        <td>
                          Calculate the target inspection measurement for spur
                          and helical gears using any pin size, with adjustable
                          profile shift
                        </td>
                        <td>Lite for storage</td>
                      </tr>
                      <tr>
                        <td>Measurement over Pins for Worms</td>
                        <td>
                          Calculate the target inspection measurement for worm
                          gears using any pin size, with adjustable backlash
                        </td>
                        <td>Pro</td>
                      </tr>
                      <tr>
                        <td>Rack</td>
                        <td>
                          Calculate the target inspection measurement for a gear
                          rack using any pin size
                        </td>
                        <td>Free</td>
                      </tr>
                      <tr>
                        <td>Span</td>
                        <td>
                          Calculate the target inspection measurements for gears
                          based on measuring across a fixed number of teeth{" "}
                        </td>
                        <td>Lite for Storage</td>
                      </tr>
                      <tr>
                        <td>Vari-Roll</td>
                        <td>
                          Calculate the test spacing (center distance) for two
                          gears on a Vari-Roll (or equivalent) double flank roll
                          tester
                        </td>
                        <td>Free</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Collapse>
            </Card>
          </Row>
          <Footer></Footer>
        </Col>

        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
    </Container>
  );
}
