import React from "react";

export default function ComparePlansTable() {
  return (
    <div className="table-responsive">
      <table className="table text-center" style={{ maxWidth: "800px" }}>
        <thead>
          <tr>
            <th style={{ width: 34 + "%" }}></th>
            <th style={{ width: 22 + "%" }}>Free</th>
            <th style={{ width: 22 + "%" }}>Lite</th>
            <th style={{ width: 22 + "%" }}>Pro</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="text-start">
              # Calculators
            </th>
            <td>{"5+"}</td>
            <td>{"18"}</td>
            <td>All 23</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">
              Gear Types Supported
            </th>
            <td>Spur</td>
            <td>Spur</td>
            <td>Spur, Helical</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">
              Best for...
            </th>
            <td>Early exploration</td>
            <td>Hobbyists & Machinists</td>
            <td>Design Firms & Gear Shops</td>
          </tr>
        </tbody>

        <tbody>
          <tr>
            <th scope="row" className="text-start">
              Utility References
            </th>
            <td></td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">
              Save Gears and Projects
            </th>
            <td></td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">
              Rack Gears
            </th>
            <td></td>
            <td>&#x2713;</td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">
              Worm Gears
            </th>
            <td></td>
            <td></td>
            <td>&#x2713;</td>
          </tr>
          <tr>
            <th scope="row" className="text-start">
              Feeds & Speeds
            </th>
            <td></td>
            <td></td>
            <td>&#x2713;</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
