import React from "react";

import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import { UnitsRadio, DecimalPlacesSelect, ButtonBlockTextArea } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorWormMOP({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  function clearInputs(event) {
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-wormgearteeth").value = "";
    document.getElementById("input-wormpitchdia").value = "";
    document.getElementById("input-pangle").value = "";
    document.getElementById("input-centerdistance").value = "";
    document.getElementById("input-axialbacklash").value = "";
    document.getElementById("input-testpindia").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    var wormteeth = document.getElementById("input-numteeth").value;
    var wormgearTeeth = document.getElementById("input-wormgearteeth").value;
    var wormPitchDia = document.getElementById("input-wormpitchdia").value;
    var pressureAngle = document.getElementById("input-pangle").value;
    var centerDistance = document.getElementById("input-centerdistance").value;
    var axialBacklash = document.getElementById("input-axialbacklash").value;
    var testPinDia = document.getElementById("input-testpindia").value;
    //var measurement = document.getElementById('input-measurement').value;

    logQA({
      page: "GCSwormmop",
      numteeth: wormgearTeeth,
      pangle: pressureAngle,
      pindia: testPinDia,
      param5: wormPitchDia,
      param6: radioValue,
      param7: centerDistance,
      param8: axialBacklash,
      param9: wormteeth,
    });

    var errorMessage = "";
    var okToCalc = true;

    // INPUT VALIDATION

    if (
      document.getElementsByName("units")[0].checked ===
      document.getElementsByName("units")[1].checked
    ) {
      //Both metric and inch are the same (checked or unchecked)
      okToCalc = false;
      errorMessage += "Gear must be either Metric or Inch\n";
    }

    if (!(wormteeth >= 1 && wormteeth <= 365)) {
      okToCalc = false;
      errorMessage += "Number of teeth on worm must be between 1 and 365\n";
    }

    if (!(wormgearTeeth >= 1 && wormgearTeeth <= 365)) {
      okToCalc = false;
      errorMessage += "Number of teeth on wormgear must be between 1 and 365\n";
    }

    if (!(wormPitchDia > 0)) {
      okToCalc = false;
      errorMessage += "Worm pitch diameter must be positive\n";
    }

    if (!(centerDistance > 0)) {
      okToCalc = false;
      errorMessage += "Center distance must be positive\n";
    }

    if (!(axialBacklash >= 0)) {
      okToCalc = false;
      errorMessage += "Axial backlash must be 0 or positive\n";
    }

    if (!(pressureAngle >= 1 && pressureAngle <= 45)) {
      okToCalc = false;
      errorMessage += "Pressure Angle must be between 1 and 45 degrees\n";
    }

    if (!(testPinDia === "" || testPinDia > 0)) {
      okToCalc = false;
      errorMessage += "Test pin diameter must be positive if defined\n";
    }
    /*
  if (!(measurement == '' || measurement > 0)){
    okToCalc = false;
    errorMessage += "Measurement must be positive if defined\n"
  }
*/

    // End input validation

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }
  }
  function calculateMOW() {
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    var wormteeth = parseFloat(document.getElementById("input-numteeth").value);
    var wormgearTeeth = parseFloat(
      document.getElementById("input-wormgearteeth").value
    );
    var wormPitchDia = parseFloat(
      document.getElementById("input-wormpitchdia").value
    );
    var pressureAngle = parseFloat(
      document.getElementById("input-pangle").value
    );
    var centerDistance = parseFloat(
      document.getElementById("input-centerdistance").value
    );
    var axialBacklash = parseFloat(
      document.getElementById("input-axialbacklash").value
    );
    var testPinDia = parseFloat(
      document.getElementById("input-testpindia").value
    );
    //var measurement = 0; //parseFloat(document.getElementById('input-measurement').value);
    var precision = parseInt(document.getElementById("precision").value);
    var pressureAngleRad = (pressureAngle * Math.PI) / 180;

    if (isNaN(axialBacklash)) {
      axialBacklash = 0;
    }

    var unitWord = "mm";
    var dpModuleWord = "Module";
    var moduleUnits = "mm";

    let wormPitchDiaInput = wormPitchDia;
    let centerDistanceInput = centerDistance;
    let axialBacklashInput = axialBacklash;
    let testPinDiaInput = testPinDia;
    //let measurementInput = measurement;

    if (radioValue === "in") {
      // Convert units to mm from inch, if inch is selected
      wormPitchDia = wormPitchDia * 25.4;
      centerDistance = centerDistance * 25.4;
      axialBacklash = axialBacklash * 25.4;
      testPinDia = testPinDia * 25.4;
      //measurement = measurement * 25.4;
      unitWord = "in";
      dpModuleWord = "Diametral Pitch";
      moduleUnits = "1/in";
    }

    // BEGIN CALCULATIONS

    let wormgearPitchDia = 2 * centerDistance - wormPitchDia;
    let axialPitch = (Math.PI * wormgearPitchDia) / wormgearTeeth;
    let moduleWormgear = 25.4 / (Math.PI / (axialPitch / 25.4)); // worm axial pitch = wormgear circular pitch. therefore pi/CP = DP

    let wormLead = wormteeth * axialPitch;
    let leadAngleRad = Math.atan(wormLead / Math.PI / wormPitchDia);

    let axialPressureAngleRad = Math.atan(
      Math.tan(pressureAngleRad) / Math.cos(leadAngleRad)
    );
    let pitchDiaUndersize = axialBacklash / Math.tan(axialPressureAngleRad);

    let d3 = wormPitchDia - pitchDiaUndersize; // Undersize pitch diameter
    let lambda3 = Math.atan(wormLead / Math.PI / d3); // Lead Angle at Undersize Pitch Diameter

    // Find the best pin diameter

    let dpin =
      (d3 *
        Math.sin(
          ((Math.PI / 2) * Math.sin(lambda3) * Math.sin(lambda3)) / wormteeth
        )) /
      (Math.sin(lambda3) *
        Math.cos(
          pressureAngleRad +
            ((Math.PI / 2) *
              Math.sin(lambda3) *
              Math.sin(lambda3) *
              Math.sin(lambda3)) /
              wormteeth
        ));

    // Find the MOP from best pin

    let theta_t = Math.atan(Math.tan(pressureAngleRad) / Math.sin(lambda3)); // Transverse Pressure Angle
    let F2 =
      (d3 * Math.cos(theta_t)) /
      Math.cos(
        theta_t +
          Math.atan(
            (Math.sin(lambda3) * Math.cos(pressureAngleRad)) /
              (d3 / dpin + Math.sin(pressureAngleRad))
          )
      ); // Diameter to center of pins

    let M = F2 + dpin; // Measurement over Best Pins

    // Adjust MOP from available pin

    let M3;
    if (testPinDia) {
      let delta_M = (1 + 1 / Math.sin(pressureAngleRad)) * (testPinDia - dpin);
      M3 = M + delta_M;
    }

    // Tooth thickness difference from measurement

    // var toothThickDiff;
    // if(measurement){
    //     if(M3){
    //         toothThickDiff = (measurement-M3)*Math.tan(axialPressureAngleRad);
    //     }
    //     else {
    //         toothThickDiff = (measurement-M)*Math.tan(axialPressureAngleRad);
    //     }
    // }

    // Change outputs back to inches (if inputs are inches)
    if (radioValue === "in") {
      dpin = dpin / 25.4;
      M = M / 25.4;
      testPinDia = testPinDia / 25.4;
      M3 = M3 / 25.4;
      //measurement = measurement / 25.4;
      wormLead = wormLead / 25.4;
      d3 = d3 / 25.4; // undersized pitch diameter
      axialPitch = axialPitch / 25.4;
      moduleWormgear = 25.4 / moduleWormgear;
      moduleUnits = "1/in";
    }

    // Test Measurement:\t${measurement ? measurementInput + '\t' + unitWord :'Unspecified'}
    // Measured Tooth Difference:\t${measurement ? toothThickDiff.toFixed(precision) + '\t' + unitWord : 'Unspecified'}
    let textToWrite = `Inputs\nNumber of Worm Teeth:\t${wormteeth}
Number of Wormgear Teeth:\t${wormgearTeeth}
Worm Pitch Diameter:\t${wormPitchDiaInput}\t${unitWord}
Pressure Angle:\t${pressureAngle}\tdegrees
Center Distance:\t${centerDistanceInput}\t${unitWord}
Axial Backlash:\t${axialBacklashInput}\t${unitWord}
Test Pin Diameter:\t${
      testPinDia ? testPinDiaInput + "\t" + unitWord : "Unspecified"
    }

Outputs
Best Pin Diameter:\t${dpin.toFixed(precision)}\t${unitWord}
MOP Best Pin:\t${M.toFixed(precision)}\t${unitWord}
Actual Test Pin:\t${testPinDia ? testPinDia + "\t" + unitWord : "Unspecified"}
MOP Test Pin:\t${M3 ? M3.toFixed(precision) + "\t" + unitWord : "Unspecified"}

Wormgear ${dpModuleWord}:\t${moduleWormgear.toFixed(precision)}\t${moduleUnits}
Worm Lead:\t${wormLead.toFixed(precision)}\t${unitWord}
Worm Lead Angle:\t${((leadAngleRad * 180) / Math.PI).toFixed(
      precision
    )}\tdegrees
Axial Pressure Angle:\t${((axialPressureAngleRad * 180) / Math.PI).toFixed(
      precision
    )}\tdegrees
Undersize Pitch Diameter:\t${d3.toFixed(precision)}\t${unitWord}
Undersize Lead Angle:\t${((lambda3 * 180) / Math.PI).toFixed(
      precision
    )}\tdegrees

\u00A9 ${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}
GCS0`;

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <div className="project-name">MOP (Worm)</div>
        <p>
          Calculate the design size of your worm gear, and use pins (also called
          "balls" or "spheres") for inspection.
        </p>
        <p>
          Pins or ball bearings are placed opposite each other in the thread of
          a worm and calipers are used to measure the distance across them. For
          worm gears, three pins or balls are used: two on one side, one on the
          opposite side. For odd-start worms (1-start, 3-start, etc.), the two
          balls on the same side can be in adjacent threads. For even-start
          worms (2-start, 4-start, etc.) the set of two balls should be
          separated by a thread.
        </p>
        <p>
          Information about the wormgear is also required to determine the axial
          pitch (the distance from one point on the thread to the same position
          on the next thread) of the worm. The axial pitch of the worm matches
          the circular pitch of the wormgear.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Worm Starts<span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Common values: <br />
                      1, 2, 3, 4
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-numteeth"
                  size="25"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-wormgearteeth">
                  Wormgear Toothcount<span className="required">*</span>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-wormgearteeth"
                  size="25"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-wormpitchdia">
                  Worm Pitch Diameter<span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      mm for Module, <br />
                      inch for DP
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-wormpitchdia"
                  size="25"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pange">
                  Normal Pressure Angle (degrees)
                  <span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Common values: <br />
                      20, 14.5, 25
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-pangle"
                  size="25"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-centerdistance">
                  Center Distance<span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Distance between worm and wormgear centers (inch or mm)
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-centerdistance"
                  size="25"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-axialbacklash">
                  Axial Backlash
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Axial backlash <br />
                      (inch or mm)
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-axialbacklash"
                  size="25"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-testpindia">
                  Test Pin Diameter
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      mm for Module, <br />
                      inch for DP
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-testpindia"
                  size="25"
                />
              </div>
              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/wormmop-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          Standard pins are not required (like the 1.728 or 1.92 series Van
          Keuren pins) — any set of quality high precision and known diameter
          pins that are close to the best pin diameter will work.
        </p>
        <p>
          <Image
            src="/calc-images/wormmop-2.png"
            fluid
            style={{ maxWidth: "500px" }}
          />
        </p>
      </Card>
    </>
  );
}
