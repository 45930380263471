// Import Bootstrap style elements
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { CardGroup } from "react-bootstrap";
import { Carousel } from "react-bootstrap";

import Footer from "./footer";
import PublicHeader from "./header";

export default function PublicHomePage() {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
        <Col>
          <PublicHeader />
          <Row>
            <h2 className="text-center mt-2 mb-4">
              Tap into our broad range of technology for gear making
            </h2>
          </Row>

          <Row>
            <Card>
              <CardGroup>
                <Card className="border-0">
                  <Card.Body>
                    <Card.Title className="card-heading-trio">
                      learn to make gears
                    </Card.Title>
                    <Card.Text className="card-text-trio">
                      Gears explained in clearly documented bite-sized
                      calculators to give you required dimensions and tolerances
                      for machining. We use these calculators in our shop:
                      tested so you know it will work in your shop. The wide
                      range of useful tools includes hard to find resources for
                      feeds and speeds, strength, gear cutter materials, machine
                      setups, gear cutter dimensions for worms.
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="border-0">
                  <Card.Body>
                    <Card.Title className="card-heading-trio">
                      inspection
                    </Card.Title>
                    <Card.Text className="card-text-trio">
                      Inspection methods that are accurate, affordable,
                      understandable and effective. Complex involute math
                      geometry is at the heart of our inspection calculators and
                      forms the basis for measurements. We did the math so you
                      do not have to.
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="border-0">
                  <Card.Body>
                    <Card.Title className="card-heading-trio">
                      design unique gears
                    </Card.Title>
                    <Card.Text className="card-text-trio">
                      Calculators with unconstrained parameters to allow
                      creative and highly functional designs. Nearly unlimited
                      tooth counts. Highly optimized complex gear train math for
                      double reduction transmissions of 4, 6, 8, 10 and 12 gears
                      in mesh. Hard to find tools to quickly solve commonly
                      encountered gear problems.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </CardGroup>
            </Card>
          </Row>

          <Row>
            <Carousel
              className="my-4"
              style={{
                backgroundColor: "#555",
              }}
            >
              <Carousel.Item>
                <div className="item">
                  <h3 style={{ textDecorationColor: "green" }}>
                    “Anytime I need to make a gear with over 200 teeth, this
                    calculator always gives me the most compact solution and
                    options”
                  </h3>
                  <p> - George Tahan, MicroGear</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="item">
                  <h3>
                    “I just wanted to say THANK YOU, THANK YOU, THANK YOU ...
                    Better tool than Machinery's Handbook on spur gear calc's."
                  </h3>
                  <p> - David H., Australia</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="item">
                  <h3>
                    “Wow, just what I needed, the worm gear carbide design tool
                    worked perfectly, plus inspection and tool paths...”
                  </h3>
                  <p>- Tony Altenoeder, AA Industrial</p>
                </div>
              </Carousel.Item>
            </Carousel>
          </Row>

          <Row style={{ alignItems: "center" }}>
            <Col sm={6} className="d-none d-sm-block">
              <Card className="border-0">
                <Card.Img
                  src="/main-images/home-1.png"
                  style={{ maxHeight: 500 + "px", maxWidth: 250 + "px" }}
                  alt="Card image"
                />
              </Card>
            </Col>
            <Col xs={12} sm={6}>
              <Card className="border-0">
                <Card.Body>
                  <Card.Title className="card-heading">
                    technology that drives manufacturing
                  </Card.Title>
                  <Card.Text>

                      All the knowledge and tech you need whether you run CNC or
                      manual milling or hobbing machine, or 3D
                      printers-routers-laser cutters-waterjet cutters etc. Know
                      the basis for your design and manufacture with confidence.
                      See it in 3D first, and use the DXF and STL file exports
                      to complement your CAD design. Internal and external Spur
                      and Helical Gears, Wormwheels, Worms, Sprockets, Ratchets,
                      and Splines. Standard Gears, profile shifting to modify
                      addendums and dedendums. Gear strengths for different
                      materials. Feeds and Speeds for hobbing, Ratio calculators
                      and other machinery setup calculations. Inspection for
                      your job that ties to your design, so you make less scrap
                      for ”GEARS THAT WORK”.

                    <br />
                    <br />
                    <a
                      href="/pricing"
                      className="btn btn-primary"
                      role="button"
                    >
                      Start Now
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row style={{ alignItems: "center" }}>
            <Col xs={12} sm={6}>
              <Card className="border-0">
                <Card.Img
                  src="/main-images/home-1.png"
                  style={{ maxHeight: 500 + "px", maxWidth: 250 + "px" }}
                  alt="Card image"
                />
              </Card>
            </Col>
            <Col xs={12} sm={6}>
              <Card className="border-0">
                <Card.Body>
                  <Card.Title className="card-heading">
                    experience & standards
                  </Card.Title>
                  <Card.Text>
                 
                      We've built our gear design, prototyping, and
                      manufacturing business using these same tools. These
                      calculators not only conform to international standards,
                      but have been built on decades of engineering experience,
                      and have helped users design over 3 million gears!
                   
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="row-lb-bg my-4">
            <Col xs={12} sm={6}>
              <Card className="border-0 card-centered-tp-bg">
                <Card.Body>
                  <Card.Title className="card-heading">
                    generating gears
                  </Card.Title>
                  <Card.Text>
                    Interactive gear generation so you can see what you are
                    going to get, see the changes in dimensions and shapes based
                    on parameter changes, with outputs that are useful for
                    further design, 3D printing or part plans.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6}>
              <Card className="card-centered-tp-bg border-0">
                <Card.Img
                  src="/main-images/home-2.png"
                  style={{ maxHeight: 500 + "px", maxWidth: 500 + "px" }}
                  alt="Card image"
                />
              </Card>
            </Col>
          </Row>

          <Row className="row-no-bg my-4">
            <Col xs={12} sm={6}>
              <Card className="card-centered-tp-bg border-0">
                <Card.Img
                  src="/main-images/home-3.png"
                  style={{ maxHeight: 300 + "px", maxWidth: 300 + "px" }}
                  alt="Card image"
                />
              </Card>
            </Col>
            <Col xs={12} sm={6}>
              <Card className="card-centered-tp-bg border-0">
                <Card.Body>
                  <Card.Title className="card-heading">
                    documentation and storage
                  </Card.Title>
                  <Card.Text>
                    Save your projects, gears, machines, speeds and feeds and
                    other vital information. Output and report you results for
                    process documentation.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="row-lb-bg">
            <Col xs={12} sm={6}>
              <Card className="card-centered-tp-bg border-0">
                <Card.Body>
                  <Card.Title className="card-heading">
                    thoroughly tested and inspected
                  </Card.Title>
                  <Card.Text>
                    Our calculators have been used in our shop extensively, but
                    they have also been in industry for 3 years. With over 3
                    million gears designed, they have been used for many
                    industries in a variety of shops-globally. Our extensive
                    library of gear technology and training, coupled with
                    programming and math skills have created a broad and useful
                    set of tools. The inspection tools are a key part of any
                    manufacturing process. You must design and manufacture. But
                    if you do not inspect, iterate and learn, you don't have all
                    the tools you need
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6}>
              <Card className="card-centered-tp-bg border-0">
                <Carousel style={{ height: 300 + "px" }}>
                  <Carousel.Item
                    style={{
                      minHeight: 300 + "px",
                      backgroundImage: "url(/main-images/home-4a2.jpg)",
                      backgroundPosition: "center",
                    }}
                  ></Carousel.Item>
                  <Carousel.Item
                    style={{
                      minHeight: 300 + "px",
                      backgroundImage: "url(/main-images/home-4b2.jpg)",
                      backgroundPosition: "center",
                    }}
                  ></Carousel.Item>
                  <Carousel.Item
                    style={{
                      minHeight: 300 + "px",
                      backgroundImage: "url(/main-images/home-4c.jpg)",
                      backgroundPosition: "center",
                    }}
                  ></Carousel.Item>
                </Carousel>
              </Card>
            </Col>
          </Row>
          <Row className="row-bottom">
            <Card
              className="card-centered-tp-bg border-0"
              style={{ maxWidth: "600px" }}
            >
              <Card.Title className="card-heading-bottom">
                calculators & plans
              </Card.Title>
              <Card.Text className="card-text-bottom">
                <span style={{ textWrap: "balance" }}>
                  We've tailored our plans to mesh with everyone from weekend
                  tinkerers to hobbyists machinists to professional gear shops
                  and makers!
                </span>
              
                <br />
                <a href="/pricing" className="btn btn-light mt-4" role="button">
                  See Plans
                </a>
              </Card.Text>
            </Card>
          </Row>

          <Footer />
        </Col>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
    </Container>
  );
}
